import { isBrowser } from "react-device-detect"

export default class GridLayout {
  constructor(timelineData) {
    this.data = {}
    this.timelineData = timelineData
  }

  /* TODO: Rename this as it's more of an initializer. */
  getTotalTimeRange() {
    this.data.min_date = this.timelineData.employers[0].date.start
    this.data.max_date = this.timelineData.employers[0].date.end
    this.timelineData.employers.forEach(element => {
      if (element.date.start < this.data.min_date) {
        this.data.min_date = element.date.start

      }
      if (element.date.end > this.data.max_date) {
        this.data.max_date = element.date.end
      }
    });

    return (this.data.max_date - this.data.min_date)

  }

  getData() {
    if (!this.data.min_date) {
      this.getTotalTimeRange()
    }
    return this.data
  }


  /* TODO: This gridify logic can be rolled into the sort-by-type
           code once we are exclusively using the sorted-by-type
           data for rendering the view. */
  gridifyElements(elements) {
    let layoutdata = this.getData()
    
    elements.forEach(element => {
      element.gridData = {}
      element.gridData.grid_row_start = layoutdata.max_date - element.date.end + 2
      element.gridData.grid_row_end = layoutdata.max_date - element.date.start + 2
      if (element.layout_hint) {
        element.gridData.grid_column_start = element.layout_hint.column + 4
        element.gridData.grid_column_end = element.gridData.grid_column_start + element.layout_hint.width
      }

      if (element.employers) {
        this.gridifyElements(element.employers)
      } else if (element.orgs) {
        this.gridifyElements(element.orgs)
      } else if (element.projects) {
        this.gridifyElements(element.projects)
      }
    })

  }


  gridifyData() {
    let layoutdata = this.getData()

    this.gridifyElements(this.timelineData.employers)
    return this.timelineData
  }


  sortElementsByType(elements, vitaeElementList, vitaeData) {

    elements.forEach( orig_element => {
      let element = JSON.parse(JSON.stringify(orig_element))
      if (element.orgs) {
        this.sortElementsByType(element.orgs, vitaeData.departments, vitaeData)
        //delete(element.orgs) /* We don't need this for our list */
      }

      if (element.projects) {
        this.sortElementsByType(element.projects, vitaeData.projects, vitaeData)
        //delete(element.projects) /* We don't need this for our list */
      }

      vitaeElementList.push(element)
    })
  }
  
  /* return vitae data organized by type */
  sortVitaeDataByType() {
    let vitaeData = {
      employers: [],
      departments: [],
      projects: []
    }
    
    this.sortElementsByType(this.gridifyData().employers,  vitaeData.employers, vitaeData)
    return vitaeData
  }


}
