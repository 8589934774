import React from 'react'
import styles from './PageFrame.css'
import 'react-skeleton-css/styles/normalize.3.0.2.css';

export default function PageFrame({element}) {
  return (
  <div className="iroxBar">
        <div className="row">
          <div className="offset-by-two.columns">
            <h1 className="offset-by-two">Roxborough, Ian</h1>
            <div className="row offset-by-two.columns">
              <a className="button" href="/">Employment</a>
              <a className="button" href="/interests">Interests</a>
              <a className="button" href="/contact">Contact</a>
            </div>
          </div>
          {element}
        </div>
    </div>
  )
}
