import styles from './LeadBit.css'

export default function ManagerBit({manager}) {
  if (!manager) {
    return("")
  }

  return(
    <span className="lead">
      M
    </span>

  )
}