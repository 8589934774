import React from 'react'
import ReactMarkdown from 'react-markdown';
import vitaeData from './resume.json'
import './TraditionalVitaeView.css'



function DateRange({ dates }) {

  return (
    <>
      {dates.start} - {dates.end}
    </>
  )

}

function TechLead({ show }) {

  if (show !== 1) { return ("") }

  return (
    <span><b>Tech Lead</b></span>
  )
}


function Role({ role }) {
  return ('[ '.concat(role, " ]"))
}


function ProjectHeader({ title, dates, tl, role }) {
  return (
    <div className="projectHeader">
      <span className="projectTitle">
        {title}
      </span>
      <span>
        <DateRange dates={dates} />
      </span>
      <TechLead show={tl} />
      <span>
        <Role role={role} />
      </span>
    </div>
  )
}

function Project({ projectData }) {
  return (
    <>
      <ProjectHeader
        title={projectData.project}
        dates={projectData.date}
        tl={projectData.techlead}
        role={projectData.role}
      />

      <i>{projectData.preview}</i>

      <ReactMarkdown className="markdown">
        {projectData.details}
      </ReactMarkdown>
    </>
  )

}

function Org({ orgData }) {
  return (
    <>
      <h5>{orgData.org} </h5>
      <div className="orgSection">
        {dateSorter(orgData.projects).map(project => {
          return <Project key={project.id} projectData={project} />
        })}
      </div>

    </>
  )
}

function Employer({ employerData }) {
  return (
    <>
      <h4>{employerData.employer} <DateRange dates={employerData.date} /></h4>
      <div className="employerSection">
        {dateSorter(employerData.orgs).map(org => {
          return <Org key={org.id} orgData={org} />
        })}
      </div>
    </>
  )

}

function PatentApplications({ patents }) {
  if (!patents) return ("")

  return (
    <>
    <h3>Patent Applications</h3>
    
    <p>
      {patents.map(patent => {
        return (
          <div>
            <b>{patent.id}</b> : {patent.title}
          </div>)
      })} 
    </p> 
    </>)
}

function dateSorter(arr) {
  return arr.sort((a, b) => {
    return b.date.end - a.date.end
  })
}

function Employers({ data }) {
  return (
    <>
      {dateSorter(data.employers).map(employer => {
        return <Employer key={employer.id} employerData={employer} />
      })}
    </>
  )
}


export default function TraditionalVitaeView() {

  return (
    <div className="twrapper">
      <div>
        <h2>{vitaeData.personal.name}</h2>
        <h4>
          {vitaeData.personal.careertitle}
          <div className="address">{vitaeData.personal.address}</div>
        </h4>
        <h4>Summary</h4>

        <ReactMarkdown>{vitaeData.employment_summary}</ReactMarkdown>
      </div>
      <h4>Employment History</h4>
      <Employers data={vitaeData} />

      <PatentApplications patents={vitaeData.patent_applications} />
      <h3>Education</h3>
      <div className='employerSection'>


        <div className='employerSection'>
          <div className="address">BSc Computer Science and Micro-systems. (1994)</div>

          <div className="address">BSc (Hons) Pure Computing. (1995)</div>
          University of Abertay Dundee, Scotland, 1991-1995
        </div>


      </div>
      <p></p>
      <h3>Interests</h3>
      {vitaeData.personal.interest_summary}<br></br>
      More at <a href="https://irox.dev/interests">https://irox.dev/interests</a>
      <p></p>
    </div>
  )
}
