import React from 'react'
import PageFrame from './PageFrame'
import ReactMarkdown from 'react-markdown';

export default function Interests() {
  return (
    <>
    <div className="container">
      <div className="row">
        <PageFrame element={
          <div className="twelve columns">
            <h3>Interests</h3>
              <h4>Submarines</h4>
              I am the custodian/owner of a 2 person submersible called Gamma, see more at <a href="https://nektongamma.com">nektongamma.com</a>.  I've been a member of the Personal submersibles Organization ( <a href="https://psubs.org">psubs.org</a> ) since 2000, and Inner Space Science ( <a href="https://innerspacescience.org">innerspacescience.org</a> ), since 2020. Gamma is my third submarine project.

              <br></br>      
              <br></br>      
              <h4>Design and Engineering</h4>

              <p>

                <h5>Security</h5>
                <p>Deep interest in Security since a young age.  Both physical and information security.</p>

                <h5>3D printing</h5>
                <p>
                  I've been using 3D printers for over 10 years.  
                   (some designs on <a href="https://www.thingiverse.com/iroxor/designs">thingiverse</a> )
                </p>
                
                <h5>Bio-mimicry</h5>
                <p>I love designing things, finding a clean elegant solution to a problem.  Applying design lessons from nature can lead to such solutions.</p>
                <p>
                  <h5>Robotics</h5>
                  Robotics is fun, especially when combined with bio-mimicry.
                </p>
                <h5>Electronics</h5>
                <p>Don't let the magic smoke escape.</p>
                <h5>Metal work</h5>
                <p>Lathe, CNC mill, TIG welding</p>
              </p>
              <h4>Civics</h4>
              <h5>Personal Privacy</h5>
              <p>People deserve privacy where expected, including in user data stored by companies. </p> 
              <h5>Censorship</h5>
              <h5>News bias</h5> 
              <h4>Out doors</h4>
                Kayaking 
                Hiking
                Archery 

            </div>
        } />
      </div>
    </div>
    </>
  )
}
