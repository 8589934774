import React from 'react'
import {BrowserView, MobileView, isBrowser} from 'react-device-detect'
import Department from './Department'
import styles from './employer.css'

export default function Employer({id, name, date, gridData}) {
  const {start, end} = date
  
  if (name=='') return

  let gridStyle = {
    gridRowStart: gridData.grid_row_start,
    gridRowEnd: 1 ? gridData.grid_row_end : gridData.grid_row_start,
    gridColumnStart: 2,
    gridColumnEnd: 13
  }

  return (
    <>
    <BrowserView className='employer' style={gridStyle}>
      <b>{name}</b>
    </BrowserView>
    <MobileView className='employer-mobile' style={gridStyle}>
    <b>{name}</b>
    </MobileView>
    </>
  )
}
