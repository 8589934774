import React from 'react'
import {BrowserView, MobileView, isBrowser} from 'react-device-detect'
import Project from './Project'
import styles from './department.css'


export default function Department({id, name, date, gridData }) {


  let gridStyle = {
    gridRowStart: gridData.grid_row_start,
    gridRowEnd: gridData.grid_row_end,
    gridColumnStart: isBrowser ? 3 :2,
    gridColumnEnd: 13
  }
  
  return (
    <>
    <BrowserView className="department" style={gridStyle}>
      <br></br>
      {name}
    </BrowserView>
    <MobileView className="department-mobile" style={gridStyle}>

      <br></br>
      {name}
    </MobileView>
    </>
  )
}
