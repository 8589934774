import React, { View, useState } from 'react'
import styles from './project.css'
import Modal from 'react-modal';
import ReactMarkdown from 'react-markdown';
import { isBrowser } from 'react-device-detect'
import LeadBit from './LeadBit'
import ManagerBit from './ManagerBit'

Modal.setAppElement('#root')

function ProjectHeader({name}) {

  const getHeaderStyle = () => {
    let styles = 'header'.concat(" header-",(isBrowser) ? "desktop" : "mobile")
    return (styles)
  }

  return (
    <>
      <div className={getHeaderStyle()}>
        {name}
      </div>
      <div className="header-overlay">
        {name}
      </div>
    </>)
}


export default function Project({id, name, preview, summary, details, date, lead, manager, gridData}) {
  const [isOpen, setOpen] = useState()
  let gridStyle = {
    gridRowStart: gridData.grid_row_start,
    gridRowEnd: gridData.grid_row_end,
    gridColumnStart: gridData.grid_column_start,
    gridColumnEnd: gridData.grid_column_end
  }
 
  const getProjectPreviewStyle = () => {
    let styles = 'project-preview'.concat(" project-preview-",(isBrowser) ? "desktop" : "mobile")
    return (styles)
  }

  const getModalStyle = () => {
    let styles = 'modal'.concat("-",(isBrowser) ? "desktop" : "mobile")
    return (styles)
  }
  
  return (
    <div className="project" style={gridStyle}>
      <ProjectHeader name={name}/>

      <div className='lead-position'><LeadBit lead={lead}/><ManagerBit manager={manager}/></div>

      <div className="project-summary">

        {summary}

        <a className="project-more" onClick={() => {
                setOpen(true);
        }}> More... </a>

        <Modal
          isOpen={isOpen}
          className={getModalStyle()}
          closeTimeoutMS={600} 
          shouldCloseOnOverlayClick={true}
          onRequestClose={() => {setOpen(false)}}
          overlayClassName="modal-overlay">

            <div className='modal-content'>

                <div className="close" onClick={() => {
                setOpen(false);
                }}>&times;</div>

                <div className="modal-header">
                    {name}
                </div>
                <p>
                  <i>{preview}</i>
                </p>

                <p>
                  <ReactMarkdown>{details}</ReactMarkdown>
                </p>

            </div>
        </Modal>
          

      </div>
      <div className={getProjectPreviewStyle()}>
        {preview}
      </div>
    </div>
  )
}
