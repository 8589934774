import React from 'react'
import styles from './timemarks.css'

export default function TimeMarks() {

  /* TODO: Consider generating these from the vitaedata dates. */
  let notibleDates = [
    "2024",
    "2022",
    "2021",
    "2017",
    "2010",
    "2007",
    "2003",
    "2000",
    "1997",
    "1995",
    "1994"

  ]

  const calcGridStyle= (date) => {
    // TODO: Dont' hard code these constants.(and Info.js, etc)
    // TODO: Support the concept of "present" (i.e. from 2014 to present day)
    return {
      gridRowStart: "2024" - date + 1,
      gridRowEnd: "2024" - date + 1,
    }

  }
  return (
    <>
      {notibleDates.map( date => {
        return (
          <div key={date} className="timemarks_base" style={calcGridStyle(date)}>
            {date}
          </div>)
      })}
    </>
  )
}
