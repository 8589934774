

import styles from './LeadBit.css'

export default function LeadBit({lead}) {
  if (!lead) {
    return("")
  }

  return(
    <span className="lead">
      TL
    </span>

  )
}
