import React from 'react'
import Employer from './Employer'
import Department from './Department'
import Project from './Project'
import TimeMarks from './TimeMarks'
import Info from './Info'
import styles from './timelinemap.css'
import { isBrowser } from 'react-device-detect'

export default function VitaeTimemap({vitaeData, annotations}) {

  const addAnnotations = () => {
    return(annotations.map(anno => {
      if (anno.type == "info") {
        return (<Info key={anno.key} text={anno.text} date={anno.date} />)
      }
    }))
  }
  
  return (
    
    <div className={'wrapper'.concat(" wrapper-", isBrowser ? "desktop" : "mobile")}>
      <div className='rollover-hint'>
        <div className='rollover-hint-txt'>Mouse over for more information.</div>
      </div>
      <TimeMarks />


      {vitaeData.employers.map(employer => {
          return <Employer key={employer.id} name={employer.employer}
          date={employer.date} gridData={employer.gridData} />
      })}

      
      {vitaeData.departments.map(dept => {
        return <Department 
                          key={dept.id}
                          name={dept.org}
                          date={dept.date}
                          gridData={dept.gridData}/>})
      }


      {vitaeData.projects.map(project => {
        return <Project 
          key={project.id}
          name={project.project}
          preview={
            project.short_preview ? project.short_preview : project.preview}
          summary={project.summary}
          details={project.details}
          date={project.date}
          lead={project.techlead}
          manager={project.manager}
          gridData={project.gridData}
          />})}

      {addAnnotations()}
    </div>
  )
}
