import React from 'react'
import 'react-skeleton-css/styles/normalize.3.0.2.css';
import Employment from './Employment';
import Interests from './Interests'
import Contact from './Contact'
import {BrowserRouter as Router, Routes, Route, useParams} from 'react-router-dom'
import TraditionalVitaeView from './TraditionalVitaeView';


function App() {
  return (
    <>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/skeleton/2.0.4/skeleton.min.css"></link>
      <Router>
        <Routes>
        <Route exact path="/" element={<Employment />} />
        <Route exact path="/interests" element={<Interests />} />
        <Route exact path="/cv" element={<TraditionalVitaeView />} />
        <Route exact path="/contact" element={<Contact />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
