import React from 'react'
import PageFrame from './PageFrame'

export default function Contact() {
  return (
    <>
      <div className="container">
        <div className="row">
          <PageFrame element={
            <div>
              <p></p>
              <p>
                Are you human?
              </p>
              <p>
                If you are human then cut'n'paste this string <code>vebkbe@tznvy.pbz</code> into the form at <a href="https://rot13.com">rot13.com</a> to get my email address.
              </p>

            </div>} />
        </div>
      </div>
    </>
  )
}
