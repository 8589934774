import React from 'react'
import Annotation from './Annotation'

export default function Info({date, text}) {

  // TODO: Stop hardcoding current date! (and TimeMarks,js, etc.)
  const calcGridStyle= (date) => {
    return {
      gridRowStart: "2024" - date + 1,
      gridRowEnd: "2024" - date + 1,
      gridColumnStart: 13,
      gridColumnEnd: 13
    }
  }
  
  return (
    <Annotation abbr="i" text={text} style={calcGridStyle(date)}/>
  )
}
