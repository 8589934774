import React from 'react'
import styles from './Annotation.css'

export default function Annotation({abbr, text, style}) {
  
  return (  
    <div className="info-grid"  style={style}>
      <div className="info">
        <div className="i">{abbr}</div>
      </div>
      <div className="info-text">
        {text}
      </div>
    </div>
  )
}
