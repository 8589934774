import React from 'react'
import VitaeTimemap from './VitaeTimemap'
import ReactMarkdown from 'react-markdown';
import vitaeData from './resume.json'
import PageFrame from './PageFrame'
import GridLayout from './GridLayout'
import LeadBit from './LeadBit'
import ManagerBit from './ManagerBit'
import './Employment.css'

export default function Employment() {
  const annotations = vitaeData.annotations
  const layout = new GridLayout(vitaeData)
  return (
    <>
    <div className="container">
      <div className="row">
        <PageFrame element={
          <div className="twelve columns">
            <div className="row">
              <h5>Summary</h5>
              
              <ReactMarkdown>{vitaeData.employment_summary}</ReactMarkdown>

              <h5>Employment overview</h5>
                <b>Staff Software Engineer: </b> Security | Privacy | Enterprise | Embedded Systems | User Interfaces
                <span style={{float: "right", }}>
                  <a href="/cv">Traditional CV version</a>
                </span>
            </div>
            
            <VitaeTimemap 
              vitaeData={layout.sortVitaeDataByType()}
              annotations={annotations}/>

            <div className='flags-key'>
              Key:
              <span className='space'/>
              <LeadBit lead={'1'}/><span> = Technical Lead</span>
              <span className='space'/>
              <ManagerBit manager={'1'}/><span> = Manager</span>
            </div>
            <p>

            </p>
              <h5>About this resume format</h5>
              <p>
                When you wear many hats, work on multiple projects and teams at the same time, a simple list of jobs and projects doesn't really tell the whole picture.  The following visual interactive CV/resume was created to help better tell my career story.  A more traditional CV view, generated from the same data, can be found <a href="/cv">here</a>.
              </p>
          </div>
        } />
      </div>
    </div>
    </>
  )
}
